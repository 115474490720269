































import { Component, Vue, Prop } from 'vue-property-decorator'
import * as actionTypes from '@/store/actionTypes'
import AuthScreen from './components/AuthScreen.vue'
import ModalHeader from './components/ModalHeader.vue'

@Component({
  name: 'RemovePassword',
  components: {
    ApproveRemove: () => import('./components/ApproveRemovePassword.vue'),
    AuthScreen,
    ModalHeader,
  },
})
export default class RemovePassword extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly isOpened!: boolean

  login = false

  loading = false

  errLabel = ''

  pass = ''

  passSuccess = false

  get header () {
    return this.login
      ? this.$t('auth.twoFactorAuth.passDeleteApprove')
      : this.$t('auth.twoFactorAuth.title')
  }

  /**
   * Close modal
   */
  close () {
    this.$emit('close')
  }

  /**
   * Emitted by AuthScreen
   */
  auth (pass: string) {
    this.login = true
    this.pass = pass
  }

  /**
   * Try to remove password
   * If success - close modal and show notify
   * If err - set login false (because it is the only one reason) and label from response
   */
  async doRemove () {
    this.loading = true
    this.errLabel = ''
    try {
      await this.$store.dispatch(actionTypes.TFA_REMOVE_PASS, this.pass)
      this.close()
      this.$q.notify({
        message: this.$t('auth.twoFactorAuth.passDeleted').toString(),
        color: 'primary',
        textColor: 'white',
        position: 'top',
        timeout: 5000,
        actions: [{ label: this.$t('common.close'), color: 'white' }],
        badgeClass: 'hidden',
      })
    } catch (e) {
      if (e.status !== 500) this.login = false
      else this.errLabel = e.error
    }
    this.loading = false
  }
}
